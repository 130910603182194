@import "https://fonts.googleapis.com/css2?family=Libre+Baskerville&family=Passions+Conflict&family=Poppins&display=swap";
@keyframes glitch {
  2%, 64% {
    transform: translate(2px)skew(0deg);
  }

  4%, 60% {
    transform: translate(-2px)skew(0deg);
  }

  62% {
    transform: translate(0)skew(5deg);
  }
}

@keyframes glitchTop {
  2%, 64% {
    transform: translate(2px, -2px);
  }

  4%, 60% {
    transform: translate(-2px, 2px);
  }

  62% {
    transform: translate(13px, -1px)skew(-13deg);
  }
}

@keyframes glitchBotom {
  2%, 64% {
    transform: translate(-2px);
  }

  4%, 60% {
    transform: translate(-2px);
  }

  62% {
    transform: translate(-22px, 5px)skew(21deg);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes rotate {
  from {
    transform: rotateY(0);
  }

  to {
    transform: rotateY(-360deg);
  }
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  min-height: 100%;
}

body {
  min-height: 100%;
  width: 100vw;
  height: 100vh;
  background-color: #141414;
  overflow-x: hidden;
}

#mouse-circle {
  width: 64px;
  height: 64px;
  z-index: 1001;
  border: 1px solid #eee;
  border-radius: 50%;
  margin: -32px 0 0 -32px;
  position: absolute;
  box-shadow: 0 0 16px #fff0;
  pointer-events: none !important;
}

@media (max-width: 768px) {
  #mouse-circle {
    display: none;
  }
}

.cc-container--full {
  max-width: 100vw;
  height: 100vh;
  background-color: #141414;
  position: relative;
}

.cc-container__section {
  height: 100%;
  z-index: 2;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

@media (max-width: 480px) {
  .cc-container__section {
    align-items: flex-start;
  }
}

.cc-container__section-link {
  color: #ab87ff;
  padding: .5rem 0;
  font-family: Poppins, sans-serif;
  text-decoration: none;
  transition: all .3s ease-in-out;
  position: relative;
}

.cc-container__section-link:before {
  content: "";
  width: .75rem;
  height: 2px;
  z-index: -1;
  background-color: #ab87ff;
  position: absolute;
  bottom: 8px;
  left: 0;
}

@media (max-width: 480px) {
  .cc-container__section-link:before {
    background-color: #0000;
  }
}

.cc-container__section-link:hover:before {
  width: 100%;
  transition: all .3s ease-in-out;
}

.cc-container__section-link:not(:hover):before {
  transition: all .3s ease-in-out;
}

.cc-container__section--small {
  height: 60%;
  width: 70%;
  z-index: 2;
  padding: 2.4rem;
  display: block;
}

@media (max-width: 480px) {
  .cc-container__section--small {
    height: 80%;
    width: 100%;
    padding: 1rem;
  }
}

.cc-container__section-title {
  color: #eee;
  letter-spacing: .3rem;
  -webkit-user-select: none;
  user-select: none;
  margin-bottom: 2rem;
  font-family: Libre Baskerville, serif;
  font-size: 3rem;
  font-weight: 100;
  animation: 1s linear infinite glitch;
}

@media (max-width: 1200px) {
  .cc-container__section-title {
    font-size: 2rem;
  }
}

@media (max-width: 480px) {
  .cc-container__section-title {
    font-size: 1.25rem;
  }
}

.cc-container__section-title:before, .cc-container__section-title:after {
  content: attr(txt);
  position: absolute;
  left: 0;
}

.cc-container__section-title:before {
  clip-path: polygon(0 0, 100% 0, 100% 33%, 0 33%);
  animation: 1s linear infinite glitchTop;
}

.cc-container__section-title:after {
  clip-path: polygon(0 67%, 100% 67%, 100% 100%, 0 100%);
  animation: 1.5s linear infinite glitchBotom;
}

.cc-container__section-paragraph {
  color: #eee;
  letter-spacing: .05rem;
  -webkit-user-select: none;
  user-select: none;
  padding: .25rem 0;
  font-family: Poppins, sans-serif;
  font-size: 1.25rem;
}

@media (max-width: 1200px) {
  .cc-container__section-paragraph {
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .cc-container__section-paragraph {
    font-size: .875rem;
  }
}

.cc-container__section-far-text {
  z-index: 1;
  opacity: .5;
  color: #1f1f1f;
  -webkit-user-select: none;
  user-select: none;
  writing-mode: vertical-rl;
  text-orientation: mixed;
  height: 100vh;
  text-align: center;
  padding: 0 1rem;
  font-family: Passions Conflict, cursive;
  font-size: 16rem;
  position: absolute;
  bottom: 0;
  left: 0;
}

@media (max-width: 1440px) {
  .cc-container__section-far-text {
    font-size: 12rem;
  }
}

@media (max-width: 1200px) {
  .cc-container__section-far-text {
    font-size: 8rem;
  }
}

@media (max-width: 768px) {
  .cc-container__section-far-text {
    font-size: 6rem;
  }
}

@media (max-width: 480px) {
  .cc-container__section-far-text {
    display: none;
  }
}

.cc-container__section-button {
  color: #eee;
  letter-spacing: .3rem;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  background: none;
  border: none;
  margin: 2rem 0;
  padding: 0;
  font-family: Poppins, sans-serif;
  font-size: 1.25rem;
  font-weight: 100;
  transition: all .3s ease-in-out;
  position: relative;
}

@media (max-width: 1200px) {
  .cc-container__section-button {
    font-size: 1rem;
  }
}

.cc-container__section-social {
  width: 20%;
  height: 10%;
  color: #eee;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

@media (max-width: 1440px) {
  .cc-container__section-social {
    width: 30%;
  }
}

@media (max-width: 1200px) {
  .cc-container__section-social {
    width: 40%;
  }
}

@media (max-width: 480px) {
  .cc-container__section-social {
    width: 100%;
  }
}

.cc-container__section-social-link {
  height: 100%;
  width: 100%;
  cursor: pointer;
  color: #eee;
  text-decoration: none;
  position: relative;
}

.cc-container__section-social i {
  height: 100%;
  background-color: #ab87ff;
  justify-content: center;
  align-items: center;
  font-size: 1.25rem;
  transition: all .3s ease-in-out;
  display: flex;
}

.cc-container__section-social i:hover {
  color: #ab87ff;
  background-color: #141414;
  transition: all .3s ease-in-out;
}

.pg-wrapper {
  z-index: 1;
  max-width: 100vw;
  padding-right: 0 !important;
}

.pg-pips {
  padding: 0 1rem;
}

@media (max-width: 480px) {
  .pg-pips {
    display: none;
  }
}

.cc-navigation {
  width: 100%;
  z-index: 3;
  margin: 0;
  padding: 0 10rem;
  position: absolute;
  top: 0;
  right: 0;
}

@media (max-width: 768px) {
  .cc-navigation {
    padding: 0 5rem;
  }
}

@media (max-width: 480px) {
  .cc-navigation {
    margin: 0 auto;
    padding: 0;
  }
}

.cc-navigation__icons {
  width: 2.8rem;
  height: 2.8rem;
  color: #ab87ff;
  cursor: pointer;
  background-color: #1f1f1f;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  transition: all .3s ease-in-out;
  display: flex;
}

.cc-navigation__icons i {
  font-size: 1.5rem;
}

.cc-navigation__logo {
  width: 50px;
  height: 40px;
  margin-right: auto;
  box-shadow: 6px 5px 15px -10px #141414;
}

.cc-navigation__logo--image {
  width: 100%;
  height: 100%;
  -webkit-user-select: none;
  user-select: none;
}

.cc-navigation #js-cc-hamburgerMenu {
  z-index: 3;
}

.cc-navigation__container--open {
  width: 100vw;
  height: 100vh;
  background-color: #141414;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  transition: all .3s ease-in-out;
  animation: .3s ease-in-out fadeIn;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2 !important;
  display: flex !important;
}

.cc-navigation__container--closed {
  z-index: -999;
  transition: all .3s ease-in-out;
  display: none;
}

.cc-navigation__menu {
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 2rem;
  padding: 1rem;
  display: flex;
}

.cc-navigation__item {
  vertical-align: middle;
  height: 15%;
  width: 30%;
  -webkit-user-select: none;
  user-select: none;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
  font-family: Poppins, sans-serif;
  font-size: 4.8rem;
  transition: all .3s ease-in-out;
  display: flex;
  position: relative;
}

@media (max-width: 1920px) {
  .cc-navigation__item {
    font-size: 3.6rem;
  }
}

@media (max-width: 1440px) {
  .cc-navigation__item {
    font-size: 2.4rem;
  }
}

@media (max-width: 768px) {
  .cc-navigation__item {
    width: 50%;
  }
}

.cc-navigation__link {
  color: #f2f2f2;
  text-align: center;
  width: 100%;
  background-color: #1f1f1f;
  border-radius: 15px;
  padding: 2rem;
  text-decoration: none;
  transition: all .3s ease-in-out;
  position: relative;
}

.cc-navigation__link:after {
  content: "";
  width: 0%;
  height: 0;
  background-color: #ab87ff;
  border-radius: 15px;
  transition: all .3s ease-in-out;
  position: absolute;
  top: 50%;
  left: 0;
}

.cc-navigation__link:hover:after {
  content: "";
  width: 60%;
  height: 10%;
  background-color: #ab87ff;
  border-radius: 15px;
  transition: all .3s ease-in-out;
  position: absolute;
  top: 50%;
  left: 20%;
}

.cc-navigation [data-tooltip] {
  position: relative;
}

.cc-navigation [data-tooltip]:after {
  text-align: center;
  content: attr(data-tooltip);
  color: #fff;
  visibility: hidden;
  background-color: #000;
  padding: 8px;
  position: absolute;
  bottom: -75%;
}

.cc-navigation [data-tooltip]:hover:after {
  visibility: visible;
}

.cc-projects__grid {
  height: 80%;
  width: 60%;
  z-index: 2;
  grid-template-rows: repeat(3, 1fr);
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  margin-top: 5rem;
  display: grid;
}

@media (max-width: 480px) {
  .cc-projects__grid {
    height: 70%;
    width: 100%;
    margin-top: 8rem;
  }
}

.cc-projects__section {
  height: 100%;
  z-index: 2;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  display: flex;
}

@media (max-width: 480px) {
  .cc-projects__section {
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: 2rem;
    margin-right: 2rem;
  }
}

.cc-projects__section-card {
  z-index: 2;
  display: flex;
  position: relative;
}

.cc-projects__section-card-container {
  width: 100%;
  height: 100%;
  color: #eee;
  position: absolute;
}

.cc-projects__section-card-container .cc-projects__section-card-content {
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 2rem;
  display: flex;
}

.cc-projects__section-card-container .cc-projects__section-card-content-title {
  text-align: center;
  font-family: Libre Baskerville, serif;
  font-size: 2rem;
}

.cc-projects__section-card-container .cc-projects__section-card-content-paragraph {
  font-family: Poppins, sans-serif;
  font-family: 1.25rem;
  text-align: center;
}

.cc-projects__section-card-container .cc-projects__section-card-content-tools {
  text-align: center;
  font-family: Poppins, sans-serif;
  font-size: .75rem;
}

.cc-projects__section-card-container .cc-projects__section-card-content-link {
  color: #1f1f1f;
  font-family: Poppins, sans-serif;
  text-decoration: none;
}

.cc-projects__section-card-container:hover .cc-projects__section-card--one-multi-button, .cc-projects__section-card-container:hover .cc-projects__section-card--one-multi-button:focus-within, .cc-projects__section-card-container:hover .cc-projects__section-card--two-multi-button, .cc-projects__section-card-container:hover .cc-projects__section-card--two-multi-button:focus-within, .cc-projects__section-card-container:hover .cc-projects__section-card--three-multi-button, .cc-projects__section-card-container:hover .cc-projects__section-card--three-multi-button:focus-within, .cc-projects__section-card-container:hover .cc-projects__section-card--four-multi-button, .cc-projects__section-card-container:hover .cc-projects__section-card--four-multi-button:focus-within, .cc-projects__section-card-container:hover .cc-projects__section-card--five-multi-button, .cc-projects__section-card-container:hover .cc-projects__section-card--five-multi-button:focus-within {
  width: 10rem;
  height: 10rem;
}

@media (max-width: 768px) {
  .cc-projects__section-card-container:hover .cc-projects__section-card--one-multi-button, .cc-projects__section-card-container:hover .cc-projects__section-card--one-multi-button:focus-within, .cc-projects__section-card-container:hover .cc-projects__section-card--two-multi-button, .cc-projects__section-card-container:hover .cc-projects__section-card--two-multi-button:focus-within, .cc-projects__section-card-container:hover .cc-projects__section-card--three-multi-button, .cc-projects__section-card-container:hover .cc-projects__section-card--three-multi-button:focus-within, .cc-projects__section-card-container:hover .cc-projects__section-card--four-multi-button, .cc-projects__section-card-container:hover .cc-projects__section-card--four-multi-button:focus-within, .cc-projects__section-card-container:hover .cc-projects__section-card--five-multi-button, .cc-projects__section-card-container:hover .cc-projects__section-card--five-multi-button:focus-within {
    width: 5rem;
    height: 5rem;
  }
}

.cc-projects__section-card--one {
  background-color: #ab87ff;
  grid-area: 1 / 1 / auto / 3;
  transition: all .3s ease-in-out;
  position: relative;
}

@media (max-width: 768px) {
  .cc-projects__section-card--one {
    grid-column: 1 / 4;
  }
}

.cc-projects__section-card--one-multi-button {
  z-index: 0;
  width: 0;
  height: 0;
  border-radius: 100%;
  transition: all .25s cubic-bezier(.25, 0, 0, 1);
  position: absolute;
  top: 2.25rem;
  left: 2.25rem;
  transform: translate(-50%, -50%);
}

.cc-projects__section-card--one-multi-button button {
  width: 3rem;
  height: 3rem;
  color: #ab87ff;
  cursor: pointer;
  background: #eee;
  border: none;
  border-radius: 100%;
  place-items: center;
  font-size: 1.5rem;
  transition: all .25s cubic-bezier(.25, 0, 0, 1);
  display: grid;
  position: absolute;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 0 -.25rem #000;
}

@media (max-width: 768px) {
  .cc-projects__section-card--one-multi-button button {
    width: 2rem;
    height: 2rem;
    font-size: .75rem;
  }
}

.cc-projects__section-card--one-multi-button button:nth-child(2) {
  color: #eee;
  background: #1f1f1f;
  border: 2px solid #eee;
}

.cc-projects__section-card--one-multi-button button:hover {
  color: #eee;
  background: #1f1f1f;
  border: 2px solid #1f1f1f;
  box-shadow: 0 0 1rem -.25rem #000;
}

.cc-projects__section-card--one-multi-button button:hover:nth-child(2) {
  color: #ab87ff;
  background: #eee;
  border: 2px solid #eee;
}

.cc-projects__section-card--one-multi-button button:first-child:nth-last-child(2):nth-child(1), .cc-projects__section-card--one-multi-button button:first-child:nth-last-child(2) ~ :nth-child(1) {
  top: 18.75%;
  left: 37.5%;
}

.cc-projects__section-card--one-multi-button button:first-child:nth-last-child(2):nth-child(2), .cc-projects__section-card--one-multi-button button:first-child:nth-last-child(2) ~ :nth-child(2) {
  top: 37.5%;
  left: 18.75%;
}

.cc-projects__section-card--two {
  background-color: #ab87ff;
  grid-area: 1 / 3 / 3 / 4;
  transition: all .3s ease-in-out;
  position: relative;
}

@media (max-width: 768px) {
  .cc-projects__section-card--two {
    grid-area: 2 / 1 / auto / 4;
  }
}

.cc-projects__section-card--two-multi-button {
  z-index: 0;
  width: 0;
  height: 0;
  border-radius: 100%;
  transition: all .25s cubic-bezier(.25, 0, 0, 1);
  position: absolute;
  top: 2.25rem;
  right: 2.25rem;
  transform: translate(50%, -50%);
}

@media (max-width: 768px) {
  .cc-projects__section-card--two-multi-button {
    left: 2.25rem;
    transform: translate(-50%, -50%);
  }
}

.cc-projects__section-card--two-multi-button button {
  width: 3rem;
  height: 3rem;
  color: #ab87ff;
  cursor: pointer;
  background: #eee;
  border: none;
  border-radius: 100%;
  place-items: center;
  font-size: 1.5rem;
  transition: all .25s cubic-bezier(.25, 0, 0, 1);
  display: grid;
  position: absolute;
  transform: translate(50%, -50%);
  box-shadow: 0 0 0 -.25rem #000;
}

@media (max-width: 768px) {
  .cc-projects__section-card--two-multi-button button {
    width: 2rem;
    height: 2rem;
    font-size: .75rem;
    transform: translate(-50%, -50%);
  }
}

.cc-projects__section-card--two-multi-button button:nth-child(2) {
  color: #eee;
  background: #1f1f1f;
  border: 2px solid #eee;
}

.cc-projects__section-card--two-multi-button button:hover {
  color: #eee;
  background: #1f1f1f;
  border: 2px solid #1f1f1f;
  box-shadow: 0 0 1rem -.25rem #000;
}

.cc-projects__section-card--two-multi-button button:hover:nth-child(2) {
  color: #ab87ff;
  background: #eee;
  border: 2px solid #eee;
}

.cc-projects__section-card--two-multi-button button:first-child:nth-last-child(2):nth-child(1), .cc-projects__section-card--two-multi-button button:first-child:nth-last-child(2) ~ :nth-child(1) {
  top: 18.75%;
  right: 37.5%;
}

@media (max-width: 768px) {
  .cc-projects__section-card--two-multi-button button:first-child:nth-last-child(2):nth-child(1), .cc-projects__section-card--two-multi-button button:first-child:nth-last-child(2) ~ :nth-child(1) {
    top: 18.75%;
    left: 37.5%;
  }
}

.cc-projects__section-card--two-multi-button button:first-child:nth-last-child(2):nth-child(2), .cc-projects__section-card--two-multi-button button:first-child:nth-last-child(2) ~ :nth-child(2) {
  top: 37.5%;
  right: 18.75%;
}

@media (max-width: 768px) {
  .cc-projects__section-card--two-multi-button button:first-child:nth-last-child(2):nth-child(2), .cc-projects__section-card--two-multi-button button:first-child:nth-last-child(2) ~ :nth-child(2) {
    top: 37.5%;
    left: 18.75%;
  }
}

.cc-projects__section-card--three {
  background-color: #ab87ff;
  grid-area: 2 / 1 / 4 / 2;
  transition: all .3s ease-in-out;
  position: relative;
}

@media (max-width: 768px) {
  .cc-projects__section-card--three {
    grid-area: 3 / 1 / auto / 4;
  }
}

.cc-projects__section-card--three-multi-button {
  z-index: 0;
  width: 0;
  height: 0;
  border-radius: 100%;
  transition: all .25s cubic-bezier(.25, 0, 0, 1);
  position: absolute;
  bottom: 2.25rem;
  left: 2.25rem;
  transform: translate(-50%, 50%);
}

@media (max-width: 768px) {
  .cc-projects__section-card--three-multi-button {
    top: 2.25rem;
    transform: translate(-50%, -50%);
  }
}

.cc-projects__section-card--three-multi-button button {
  width: 3rem;
  height: 3rem;
  color: #ab87ff;
  cursor: pointer;
  background: #eee;
  border: none;
  border-radius: 100%;
  place-items: center;
  font-size: 1.5rem;
  transition: all .25s cubic-bezier(.25, 0, 0, 1);
  display: grid;
  position: absolute;
  transform: translate(-50%, 50%);
  box-shadow: 0 0 0 -.25rem #000;
}

@media (max-width: 768px) {
  .cc-projects__section-card--three-multi-button button {
    width: 2rem;
    height: 2rem;
    font-size: .75rem;
    transform: translate(-50%, -50%);
  }
}

.cc-projects__section-card--three-multi-button button:nth-child(2) {
  color: #eee;
  background: #1f1f1f;
  border: 2px solid #eee;
}

.cc-projects__section-card--three-multi-button button:hover {
  color: #eee;
  background: #1f1f1f;
  border: 2px solid #1f1f1f;
  box-shadow: 0 0 1rem -.25rem #000;
}

.cc-projects__section-card--three-multi-button button:hover:nth-child(2) {
  color: #ab87ff;
  background: #eee;
  border: 2px solid #eee;
}

.cc-projects__section-card--three-multi-button button:first-child:nth-last-child(2):nth-child(1), .cc-projects__section-card--three-multi-button button:first-child:nth-last-child(2) ~ :nth-child(1) {
  bottom: 18.75%;
  left: 37.5%;
}

@media (max-width: 768px) {
  .cc-projects__section-card--three-multi-button button:first-child:nth-last-child(2):nth-child(1), .cc-projects__section-card--three-multi-button button:first-child:nth-last-child(2) ~ :nth-child(1) {
    top: 18.75%;
    left: 37.5%;
  }
}

.cc-projects__section-card--three-multi-button button:first-child:nth-last-child(2):nth-child(2), .cc-projects__section-card--three-multi-button button:first-child:nth-last-child(2) ~ :nth-child(2) {
  bottom: 37.5%;
  left: 18.75%;
}

@media (max-width: 768px) {
  .cc-projects__section-card--three-multi-button button:first-child:nth-last-child(2):nth-child(2), .cc-projects__section-card--three-multi-button button:first-child:nth-last-child(2) ~ :nth-child(2) {
    top: 37.5%;
    left: 18.75%;
  }
}

.cc-projects__section-card--four {
  background-color: #ab87ff;
  grid-area: 3 / 2 / 4 / 4;
  transition: all .3s ease-in-out;
  position: relative;
}

@media (max-width: 768px) {
  .cc-projects__section-card--four {
    display: none;
  }
}

.cc-projects__section-card--four-multi-button {
  z-index: 0;
  width: 0;
  height: 0;
  border-radius: 100%;
  transition: all .25s cubic-bezier(.25, 0, 0, 1);
  position: absolute;
  bottom: 2.25rem;
  right: 2.25rem;
  transform: translate(50%, 50%);
}

.cc-projects__section-card--four-multi-button button {
  width: 3rem;
  height: 3rem;
  color: #ab87ff;
  cursor: pointer;
  background: #eee;
  border: none;
  border-radius: 100%;
  place-items: center;
  font-size: 1.5rem;
  transition: all .25s cubic-bezier(.25, 0, 0, 1);
  display: grid;
  position: absolute;
  transform: translate(50%, 50%);
  box-shadow: 0 0 0 -.25rem #000;
}

.cc-projects__section-card--four-multi-button button:nth-child(2) {
  color: #eee;
  background: #1f1f1f;
  border: 2px solid #eee;
}

.cc-projects__section-card--four-multi-button button:hover {
  color: #eee;
  background: #1f1f1f;
  border: 2px solid #1f1f1f;
  box-shadow: 0 0 1rem -.25rem #000;
}

.cc-projects__section-card--four-multi-button button:hover:nth-child(2) {
  color: #ab87ff;
  background: #eee;
  border: 2px solid #eee;
}

.cc-projects__section-card--four-multi-button button:first-child:nth-last-child(2):nth-child(1), .cc-projects__section-card--four-multi-button button:first-child:nth-last-child(2) ~ :nth-child(1) {
  bottom: 18.75%;
  right: 37.5%;
}

.cc-projects__section-card--four-multi-button button:first-child:nth-last-child(2):nth-child(2), .cc-projects__section-card--four-multi-button button:first-child:nth-last-child(2) ~ :nth-child(2) {
  bottom: 37.5%;
  right: 18.75%;
}

.cc-projects__section-card--five {
  opacity: 0;
  background-color: #0000;
  grid-area: 2 / 2 / 3 / 3;
  transition: all .3s ease-in-out;
  position: relative;
}

@media (max-width: 768px) {
  .cc-projects__section-card--five {
    display: none;
  }
}

.cc-skills__section {
  padding: 5rem 0;
}

@media (max-width: 480px) {
  .cc-skills__section {
    padding: 0;
  }
}

.cc-skills__carousel {
  width: 200px;
  perspective: 800px;
  z-index: 2;
  -webkit-user-select: none;
  user-select: none;
  margin: 5rem auto 0;
  position: relative;
}

@media (max-width: 768px) {
  .cc-skills__carousel {
    width: 150px;
    perspective: 1200px;
  }
}

.cc-skills__carousel-wrapper {
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  animation: 30s steps(10000, end) infinite rotate;
  position: absolute;
  transform: rotateY(-360deg)translateZ(-300px);
}

.cc-skills__carousel-item {
  width: 200px;
  height: 100px;
  position: absolute;
}

@media (max-width: 768px) {
  .cc-skills__carousel-item {
    width: 150px;
    height: 100px;
  }
}

.cc-skills__carousel-item img {
  width: 200px;
  height: 100px;
}

@media (max-width: 768px) {
  .cc-skills__carousel-item img {
    width: 150px;
    height: 100px;
  }
}

.cc-skills__carousel-item--one {
  transform: rotateY(0)translateZ(300px);
}

.cc-skills__carousel-item--two {
  transform: rotateY(30deg)translateZ(300px);
}

.cc-skills__carousel-item--three {
  transform: rotateY(60deg)translateZ(300px);
}

.cc-skills__carousel-item--four {
  transform: rotateY(90deg)translateZ(300px);
}

.cc-skills__carousel-item--five {
  transform: rotateY(120deg)translateZ(300px);
}

.cc-skills__carousel-item--six {
  transform: rotateY(150deg)translateZ(300px);
}

.cc-skills__carousel-item--seven {
  transform: rotateY(180deg)translateZ(300px);
}

.cc-skills__carousel-item--eight {
  transform: rotateY(210deg)translateZ(300px);
}

.cc-skills__carousel-item--nine {
  transform: rotateY(240deg)translateZ(300px);
}

.cc-skills__carousel-item--ten {
  transform: rotateY(270deg)translateZ(300px);
}

.cc-skills__carousel-item--eleven {
  transform: rotateY(300deg)translateZ(300px);
}

.cc-skills__carousel-item--twelve {
  transform: rotateY(330deg)translateZ(300px);
}

.cc-canvas {
  width: 100%;
  height: 100%;
}

.cc-canvas-container {
  height: 80%;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.cc-contact__section {
  height: 100%;
  z-index: 2;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  display: flex;
}

@media (max-width: 480px) {
  .cc-contact__section {
    width: 100%;
    height: 90%;
    justify-content: flex-start;
  }
}

.cc-contact__section--small {
  padding: 0;
}

@media (max-width: 480px) {
  .cc-contact__section--small {
    padding: 0 2rem;
  }
}

.cc-contact__section--small .cc-contact-content {
  width: 100%;
  height: 100%;
  flex-direction: row;
  display: flex;
  position: relative;
}

@media (max-width: 768px) {
  .cc-contact__section--small .cc-contact-content {
    flex-direction: column;
  }
}

.cc-contact__section--small .cc-contact-map {
  width: 100%;
  height: 100%;
  z-index: 2;
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
}

.cc-contact__section--small .cc-contact-map__wrapper {
  width: 100%;
  height: 100%;
}

.cc-contact__section--small .cc-contact-map__wrapper-content {
  width: 100%;
  height: 100%;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}

@media (max-width: 768px) {
  .cc-contact__section--small .cc-contact-map__wrapper-content {
    border-top-right-radius: 0;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
  }
}

.cc-contact-form {
  width: 100%;
  height: 100%;
  background-color: #1f1f1f;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-end;
  padding: 0 3rem;
  display: flex;
  position: relative;
}

@media (max-width: 1440px) {
  .cc-contact-form {
    padding: 5rem 3rem;
  }
}

@media (max-width: 768px) {
  .cc-contact-form {
    border-top-right-radius: 15px;
    border-bottom-left-radius: 0;
    padding: 1rem 1.5rem 0;
  }
}

.cc-contact-form-overlay {
  width: 55%;
  height: 100%;
  z-index: -1;
  -webkit-user-select: none;
  user-select: none;
  background-color: #1f1f1f;
  border-radius: 15px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5rem 0;
  display: flex;
  position: absolute;
}

@media (max-width: 768px) {
  .cc-contact-form-overlay {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .cc-contact-form-overlay {
    justify-content: flex-start;
  }
}

.cc-contact-form-overlay__title {
  text-align: center;
  color: #eee;
  margin-bottom: 2rem;
  font-family: Libre Baskerville, serif;
  font-size: 2.5rem;
  animation: .5s ease-in-out fadeIn;
}

@media (max-width: 1920px) {
  .cc-contact-form-overlay__title {
    font-size: 2rem;
  }
}

@media (max-width: 1440px) {
  .cc-contact-form-overlay__title {
    font-size: 1.5rem;
  }
}

.cc-contact-form-overlay__accents {
  color: #ab87ff;
}

.cc-contact-form-overlay__paragraph {
  text-align: center;
  color: #eee;
  font-family: Poppins, sans-serif;
  font-size: 1.5rem;
  animation: .5s ease-in-out fadeIn;
}

@media (max-width: 1920px) {
  .cc-contact-form-overlay__paragraph {
    font-size: 1.25rem;
  }
}

@media (max-width: 1440px) {
  .cc-contact-form-overlay__paragraph {
    font-size: 1.125rem;
  }
}

.cc-contact-form-overlay__image {
  width: 20rem;
  height: 20rem;
  color: #ab87ff;
  animation: .5s ease-in-out fadeIn;
}

@media (max-width: 768px) {
  .cc-contact-form-overlay__image {
    width: 10rem;
    height: 10rem;
  }
}

.cc-contact-form-overlay--open {
  z-index: 1;
}

.cc-contact-form-title {
  color: #eee;
  letter-spacing: .3rem;
  -webkit-user-select: none;
  user-select: none;
  font-family: Libre Baskerville, serif;
  font-weight: 100;
  animation: 1s linear infinite glitch;
}

.cc-contact-form-title:before, .cc-contact-form-title:after {
  content: attr(txt);
  position: absolute;
  left: 0;
}

.cc-contact-form-title:before {
  clip-path: polygon(0 0, 100% 0, 100% 33%, 0 33%);
  animation: 1s linear infinite glitchTop;
}

.cc-contact-form-title:after {
  clip-path: polygon(0 67%, 100% 67%, 100% 100%, 0 100%);
  animation: 1.5s linear infinite glitchBotom;
}

.cc-contact-form__field {
  width: 100%;
  flex-direction: column;
  padding: 1rem 0;
  display: flex;
}

@media (max-width: 768px) {
  .cc-contact-form__field {
    padding: .25rem 0;
  }
}

.cc-contact-form__field label {
  color: #eee;
  padding: .5rem 0;
  font-family: Poppins, sans-serif;
  font-size: 1.125rem;
}

@media (max-width: 768px) {
  .cc-contact-form__field label {
    font-size: .75rem;
  }
}

.cc-contact-form__field input {
  color: #eee;
  background-color: #141414;
  border: none;
  padding: 1rem;
  font-family: Poppins, sans-serif;
}

@media (max-width: 768px) {
  .cc-contact-form__field input {
    padding: .5rem;
    font-size: .75rem;
  }
}

.cc-contact-form__field input::placeholder {
  color: #eee;
  opacity: .375;
}

.cc-contact-form__textarea {
  width: 100%;
  height: 50%;
  flex-direction: column;
  padding: 1rem 0;
  display: flex;
}

@media (max-width: 768px) {
  .cc-contact-form__textarea {
    padding: .25rem;
  }
}

.cc-contact-form__textarea label {
  color: #eee;
  padding: .5rem 0;
  font-family: Poppins, sans-serif;
  font-size: 1.125rem;
}

@media (max-width: 768px) {
  .cc-contact-form__textarea label {
    font-size: .75rem;
  }
}

.cc-contact-form__textarea textarea {
  color: #eee;
  resize: none;
  background-color: #141414;
  border: none;
  padding: 1rem;
  font-family: Poppins, sans-serif;
}

@media (max-width: 768px) {
  .cc-contact-form__textarea textarea {
    padding: .5rem;
    font-size: .75rem;
  }
}

.cc-contact-form__textarea textarea::placeholder {
  color: #eee;
  opacity: .375;
}

.cc-contact-form__textarea-field {
  height: 100%;
}

.cc-contact-form__button {
  color: #eee;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  background: none;
  border: 2px solid #141414;
  border-radius: 5px;
  margin-bottom: .75rem;
  padding: .5rem 1rem;
  font-family: Poppins, sans-serif;
  font-size: 1rem;
  font-weight: 100;
  transition: all .3s ease-in-out;
  position: relative;
}

@media (max-width: 768px) {
  .cc-contact-form__button {
    padding: .375rem .675rem;
    font-size: .75rem;
  }
}

.cc-contact-form__button:hover {
  color: #141414;
  background-color: #eee;
  border: 2px solid #eee;
}

/*# sourceMappingURL=index.1fedf5f8.css.map */
