// Global variables

// Fonts

@import url("https://fonts.googleapis.com/css2?family=Libre+Baskerville&family=Passions+Conflict&family=Poppins&display=swap");

$passions: "Passions Conflict", cursive;
$libre: "Libre Baskerville", serif;
$poppins: "Poppins", sans-serif;

// Colors
$white: #eeeeee;
$black: #141414;
$lavender: #ab87ff;
$farTextBlack: #1f1f1f;

// Text sizes
$rem: 10px;

$font-size-16: 1.6rem;
$font-size-24: 2.4rem;
$font-size-36: 3.6rem;
$font-size-48: 4.8rem;
$font-size-64: 6.4rem;

// Three.JS / Canvas

// Animations
@keyframes glitch {
  2%,
  64% {
    transform: translate(2px, 0) skew(0deg);
  }
  4%,
  60% {
    transform: translate(-2px, 0) skew(0deg);
  }
  62% {
    transform: translate(0, 0) skew(5deg);
  }
}

@keyframes glitchTop {
  2%,
  64% {
    transform: translate(2px, -2px);
  }
  4%,
  60% {
    transform: translate(-2px, 2px);
  }
  62% {
    transform: translate(13px, -1px) skew(-13deg);
  }
}

@keyframes glitchBotom {
  2%,
  64% {
    transform: translate(-2px, 0);
  }
  4%,
  60% {
    transform: translate(-2px, 0);
  }
  62% {
    transform: translate(-22px, 5px) skew(21deg);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes rotate {
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(-360deg);
  }
}

// Global selector
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  // overflow-x: hidden;
}

html {
  min-height: 100%;
}

body {
  min-height: 100%;
  background-color: $black;
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
}

// Mouse circle following cursor
#mouse-circle {
  position: absolute;
  width: 64px;
  height: 64px;
  margin: -32px 0px 0px -32px;
  border: 1px solid $white;
  border-radius: 50%;
  pointer-events: none !important;
  box-shadow: 0 0 16px rgba(255, 255, 255, 0);
  z-index: 1001;
  @media (max-width: 768px) {
    display: none;
  }
}

.cc {
  &-container {
    &--full {
      max-width: 100vw;
      height: 100vh;
      background-color: $black;
      position: relative;
    }

    &__section {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      height: 100%;
      z-index: 2;
      @media (max-width: 480px) {
        align-items: flex-start;
      }

      &-link {
        color: $lavender;
        font-family: $poppins;
        text-decoration: none;
        position: relative;
        transition: all 0.3s ease-in-out;
        padding: 0.5rem 0px;

        &::before {
          content: "";
          background-color: $lavender;
          position: absolute;
          left: 0;
          bottom: 8px;
          width: 0.75rem;
          height: 2px;
          z-index: -1;
          @media (max-width: 480px) {
            background-color: transparent;
          }
        }

        &:hover {
          &::before {
            transition: all 0.3s ease-in-out;
            width: 100%;
          }
        }

        &:not(:hover) {
          &::before {
            transition: all 0.3s ease-in-out;
          }
        }
      }

      &--small {
        display: block;
        height: 60%;
        width: 70%;
        padding: 2.4rem;
        z-index: 2;
        @media (max-width: 480px) {
          padding: 1rem;
          height: 80%;
          width: 100%;
        }
      }

      &-title {
        font-size: 3rem;
        margin-bottom: 2rem;
        font-family: $libre;
        font-weight: 100;
        color: $white;
        letter-spacing: 0.3rem;
        animation: glitch 1s linear infinite;
        // Make text unselectable
        -webkit-user-select: none; /* Safari */
        -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* IE10+/Edge */
        user-select: none; /* Standard */
        @media (max-width: 1200px) {
          font-size: 2rem;
        }
        @media (max-width: 480px) {
          font-size: 1.25rem;
        }

        &::before,
        &::after {
          content: attr(txt);
          position: absolute;
          left: 0;
        }

        &::before {
          animation: glitchTop 1s linear infinite;
          clip-path: polygon(0 0, 100% 0, 100% 33%, 0 33%);
          -webkit-clip-path: polygon(0 0, 100% 0, 100% 33%, 0 33%);
        }

        &::after {
          animation: glitchBotom 1.5s linear infinite;
          clip-path: polygon(0 67%, 100% 67%, 100% 100%, 0 100%);
          -webkit-clip-path: polygon(0 67%, 100% 67%, 100% 100%, 0 100%);
        }
      }

      &-paragraph {
        font-size: 1.25rem;
        font-family: $poppins;
        color: $white;
        letter-spacing: 0.05rem;
        padding: 0.25rem 0;
        // Make text unselectable
        -webkit-user-select: none; /* Safari */
        -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* IE10+/Edge */
        user-select: none; /* Standard */
        @media (max-width: 1200px) {
          font-size: 1rem;
        }
        @media (max-width: 480px) {
          font-size: 0.875rem;
        }
      }

      &-far-text {
        position: absolute;
        bottom: 0;
        left: 0;
        font-size: 16rem;
        z-index: 1;
        font-family: $passions;
        opacity: 0.5;
        color: $farTextBlack;
        // Make text unselectable
        -webkit-user-select: none; /* Safari */
        -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* IE10+/Edge */
        user-select: none; /* Standard */
        writing-mode: vertical-rl;
        text-orientation: mixed;
        height: 100vh;
        text-align: center;
        padding: 0 1rem;
        @media (max-width: 1440px) {
          font-size: 12rem;
        }
        @media (max-width: 1200px) {
          font-size: 8rem;
        }
        @media (max-width: 768px) {
          font-size: 6rem;
        }
        @media (max-width: 480px) {
          display: none;
        }
      }

      &-button {
        position: relative;
        margin: 2rem 0;
        padding: 0;
        font-size: 1.25rem;
        font-family: $poppins;
        font-weight: 100;
        color: $white;
        letter-spacing: 0.3rem;
        transition: all 0.3s ease-in-out;
        background: transparent;
        border: none;
        cursor: pointer;
        // Make text unselectable
        -webkit-user-select: none; /* Safari */
        -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* IE10+/Edge */
        user-select: none; /* Standard */
        @media (max-width: 1200px) {
          font-size: 1rem;
        }
      }

      &-social {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 20%;
        height: 10%;
        color: $white;
        @media (max-width: 1440px) {
          width: 30%;
        }
        @media (max-width: 1200px) {
          width: 40%;
        }
        @media (max-width: 480px) {
          width: 100%;
        }

        &-link {
          height: 100%;
          width: 100%;
          position: relative;
          cursor: pointer;
          color: $white;
          text-decoration: none;
        }

        & i {
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 1.25rem;
          background-color: $lavender;
          transition: all 0.3s ease-in-out;

          &:hover {
            background-color: $black;
            color: $lavender;
            transition: all 0.3s ease-in-out;
          }
        }
      }
    }
  }
}

// Pageable
.pg {
  &-wrapper {
    z-index: 1;
    padding-right: 0 !important;
    max-width: 100vw;
  }

  &-pips {
    padding: 0 1rem;
    @media (max-width: 480px) {
      display: none;
    }
  }
}

// Navigation
.cc {
  &-navigation {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    padding: 0 10rem;
    width: 100%;
    z-index: 3;
    @media (max-width: 768px) {
      padding: 0 5rem;
    }
    @media (max-width: 480px) {
      padding: 0;
      margin: 0 auto;
    }

    &__icons {
      width: 2.8rem;
      height: 2.8rem;
      background-color: $farTextBlack;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all 0.3s ease-in-out;
      color: $lavender;

      cursor: pointer;
      & i {
        font-size: 1.5rem;
      }
    }

    &__logo {
      margin-right: auto;
      width: 50px;
      height: 40px;
      -webkit-box-shadow: 6px 5px 15px -10px $black;
      box-shadow: 6px 5px 15px -10px $black;

      &--image {
        width: 100%;
        height: 100%;
        -webkit-user-select: none; /* Safari */
        -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* IE10+/Edge */
        user-select: none; /* Standard */
      }
    }

    #js-cc-hamburgerMenu {
      z-index: 3;
    }

    // Overlay

    &__container {
      &--open {
        z-index: 2 !important;
        position: absolute;
        left: 0;
        top: 0;
        width: 100vw;
        height: 100vh;
        background-color: $black;
        display: flex !important;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 0;
        padding: 0;
        transition: all 0.3s ease-in-out;
        animation: fadeIn 0.3s ease-in-out;
      }

      &--closed {
        transition: all 0.3s ease-in-out;
        display: none;
        z-index: -999;
      }
    }

    &__menu {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      gap: 2rem;
      padding: 1rem;
    }

    &__item {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      vertical-align: middle;
      font-family: $poppins;
      font-size: 4.8rem;
      height: 15%;
      width: 30%;
      margin-bottom: 1rem;
      position: relative;
      transition: all 0.3s ease-in-out;
      -webkit-user-select: none; /* Safari */
      -moz-user-select: none; /* Firefox */
      -ms-user-select: none; /* IE10+/Edge */
      user-select: none; /* Standard */
      @media (max-width: 1920px) {
        font-size: 3.6rem;
      }
      @media (max-width: 1440px) {
        font-size: 2.4rem;
      }
      @media (max-width: 768px) {
        width: 50%;
      }
    }

    &__link {
      color: #f2f2f2;
      text-align: center;
      padding: 2rem;
      text-decoration: none;
      position: relative;
      transition: all 0.3s ease-in-out;
      background-color: $farTextBlack;
      width: 100%;
      border-radius: 15px;

      &::after {
        content: "";
        width: 0%;
        background-color: $lavender;
        position: absolute;
        height: 0;
        left: 0;
        top: 50%;
        border-radius: 15px;
        transition: all 0.3s ease-in-out;
      }

      &:hover::after {
        content: "";
        width: 60%;
        background-color: $lavender;
        position: absolute;
        height: 10%;
        left: 20%;
        top: 50%;
        border-radius: 15px;
        transition: all 0.3s ease-in-out;
      }
    }

    // Tooltip

    [data-tooltip] {
      position: relative;
    }

    [data-tooltip]::after {
      position: absolute;
      bottom: -75%;
      text-align: center;
      content: attr(data-tooltip);
      color: white;
      background-color: black;
      padding: 8px;
      visibility: hidden;
    }

    [data-tooltip]:hover::after {
      visibility: visible;
    }
  }
}

// Projects
.cc {
  &-projects {
    &__grid {
      display: grid;
      height: 80%;
      width: 60%;
      z-index: 2;
      margin-top: 5rem;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(3, 1fr);
      gap: 1rem;
      @media (max-width: 480px) {
        height: 70%;
        width: 100%;
        margin-top: 8rem;
      }
    }

    &__section {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      flex-wrap: nowrap;
      height: 100%;
      z-index: 2;
      @media (max-width: 480px) {
        align-items: flex-start;
        justify-content: flex-start;
        margin-left: 2rem;
        margin-right: 2rem;
      }

      &-card {
        display: flex;
        z-index: 2;
        position: relative;

        &-container {
          position: absolute;
          width: 100%;
          height: 100%;
          color: $white;

          & .cc-projects__section-card-content {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            height: 100%;
            padding: 2rem;

            &-title {
              font-family: $libre;
              font-size: 2rem;
              text-align: center;
            }

            &-paragraph {
              font-family: $poppins;
              font-family: 1.25rem;
              text-align: center;
            }

            &-tools {
              font-family: $poppins;
              font-size: 0.75rem;
              text-align: center;
            }

            &-link {
              text-decoration: none;
              color: $farTextBlack;
              font-family: $poppins;
            }
          }

          &:hover {
            .cc-projects__section-card--one-multi-button,
            .cc-projects__section-card--one-multi-button:focus-within,
            .cc-projects__section-card--two-multi-button,
            .cc-projects__section-card--two-multi-button:focus-within,
            .cc-projects__section-card--three-multi-button,
            .cc-projects__section-card--three-multi-button:focus-within,
            .cc-projects__section-card--four-multi-button,
            .cc-projects__section-card--four-multi-button:focus-within,
            .cc-projects__section-card--five-multi-button,
            .cc-projects__section-card--five-multi-button:focus-within {
              width: 10rem;
              height: 10rem;
              @media (max-width: 768px) {
                width: 5rem;
                height: 5rem;
              }
            }
          }
        }

        &--one {
          grid-column: 1 / 3;
          grid-row: 1;
          background-color: $lavender;
          position: relative;
          transition: all 0.3s ease-in-out;
          @media (max-width: 768px) {
            grid-column: 1 / 4;
          }

          &-multi-button {
            z-index: 0;
            position: absolute;
            top: 2.25rem;
            left: 2.25rem;
            border-radius: 100%;
            width: 0rem;
            height: 0rem;
            transform: translate(-50%, -50%);
            transition: 0.25s cubic-bezier(0.25, 0, 0, 1);

            & button {
              display: grid;
              place-items: center;
              position: absolute;
              width: 3rem;
              height: 3rem;
              border: none;
              border-radius: 100%;
              background: $white;
              color: $lavender;
              transform: translate(-50%, -50%);
              cursor: pointer;
              transition: 0.25s cubic-bezier(0.25, 0, 0, 1);
              box-shadow: 0 0 0rem -0.25rem black;
              font-size: 1.5rem;
              @media (max-width: 768px) {
                font-size: 0.75rem;
                width: 2rem;
                height: 2rem;
              }

              &:nth-child(2) {
                background: $farTextBlack;
                color: $white;
                border: 2px solid $white;
              }

              &:hover {
                background: $farTextBlack;
                color: $white;
                box-shadow: 0 0 1rem -0.25rem black;
                border: 2px solid $farTextBlack;
              }
              &:hover:nth-child(2) {
                background: $white;
                color: $lavender;
                border: 2px solid $white;
              }

              &:first-child:nth-last-child(2),
              &:first-child:nth-last-child(2) ~ * {
                //If there are 2 children
                &:nth-child(1) {
                  left: 37.5%;
                  top: 18.75%;
                }
                &:nth-child(2) {
                  left: 18.75%;
                  top: 37.5%;
                }
              }
            }
          }
        }

        &--two {
          grid-column: 3 / 4;
          grid-row: 1 / 3;
          background-color: $lavender;
          position: relative;
          transition: all 0.3s ease-in-out;
          @media (max-width: 768px) {
            grid-column: 1 / 4;
            grid-row: 2;
          }

          &-multi-button {
            z-index: 0;
            position: absolute;
            top: 2.25rem;
            right: 2.25rem;
            border-radius: 100%;
            width: 0rem;
            height: 0rem;
            transform: translate(50%, -50%);
            transition: 0.25s cubic-bezier(0.25, 0, 0, 1);
            @media (max-width: 768px) {
              left: 2.25rem;
              transform: translate(-50%, -50%);
            }

            & button {
              display: grid;
              place-items: center;
              position: absolute;
              width: 3rem;
              height: 3rem;
              border: none;
              border-radius: 100%;
              background: $white;
              color: $lavender;
              transform: translate(50%, -50%);
              cursor: pointer;
              transition: 0.25s cubic-bezier(0.25, 0, 0, 1);
              box-shadow: 0 0 0rem -0.25rem black;
              font-size: 1.5rem;
              @media (max-width: 768px) {
                transform: translate(-50%, -50%);
                font-size: 0.75rem;
                width: 2rem;
                height: 2rem;
              }

              &:nth-child(2) {
                background: $farTextBlack;
                color: $white;
                border: 2px solid $white;
              }
              &:hover {
                background: $farTextBlack;
                color: $white;
                box-shadow: 0 0 1rem -0.25rem black;
                border: 2px solid $farTextBlack;
              }
              &:hover:nth-child(2) {
                background: $white;
                color: $lavender;
                border: 2px solid $white;
              }

              &:first-child:nth-last-child(2),
              &:first-child:nth-last-child(2) ~ * {
                //If there are 2 children
                &:nth-child(1) {
                  right: 37.5%;
                  top: 18.75%;
                  @media (max-width: 768px) {
                    left: 37.5%;
                    top: 18.75%;
                  }
                }
                &:nth-child(2) {
                  right: 18.75%;
                  top: 37.5%;
                  @media (max-width: 768px) {
                    left: 18.75%;
                    top: 37.5%;
                  }
                }
              }
            }
          }
        }
        &--three {
          grid-column: 1 / 2;
          grid-row: 2 / 4;
          background-color: $lavender;
          position: relative;
          transition: all 0.3s ease-in-out;
          @media (max-width: 768px) {
            grid-column: 1 / 4;
            grid-row: 3;
          }

          &-multi-button {
            z-index: 0;
            position: absolute;
            bottom: 2.25rem;
            left: 2.25rem;
            border-radius: 100%;
            width: 0rem;
            height: 0rem;
            transform: translate(-50%, 50%);
            transition: 0.25s cubic-bezier(0.25, 0, 0, 1);
            @media (max-width: 768px) {
              transform: translate(-50%, -50%);
              top: 2.25rem;
            }
            & button {
              display: grid;
              place-items: center;
              position: absolute;
              width: 3rem;
              height: 3rem;
              border: none;
              border-radius: 100%;
              background: $white;
              color: $lavender;
              transform: translate(-50%, 50%);
              cursor: pointer;
              transition: 0.25s cubic-bezier(0.25, 0, 0, 1);
              box-shadow: 0 0 0rem -0.25rem black;
              font-size: 1.5rem;
              @media (max-width: 768px) {
                transform: translate(-50%, -50%);
                font-size: 0.75rem;
                width: 2rem;
                height: 2rem;
              }

              &:nth-child(2) {
                background: $farTextBlack;
                color: $white;
                border: 2px solid $white;
              }
              &:hover {
                background: $farTextBlack;
                color: $white;
                box-shadow: 0 0 1rem -0.25rem black;
                border: 2px solid $farTextBlack;
              }
              &:hover:nth-child(2) {
                background: $white;
                color: $lavender;
                border: 2px solid $white;
              }

              &:first-child:nth-last-child(2),
              &:first-child:nth-last-child(2) ~ * {
                //If there are 2 children
                &:nth-child(1) {
                  left: 37.5%;
                  bottom: 18.75%;
                  @media (max-width: 768px) {
                    left: 37.5%;
                    top: 18.75%;
                  }
                }
                &:nth-child(2) {
                  left: 18.75%;
                  bottom: 37.5%;
                  @media (max-width: 768px) {
                    left: 18.75%;
                    top: 37.5%;
                  }
                }
              }
            }
          }
        }
        &--four {
          grid-column: 2 / 4;
          grid-row: 3 / 4;
          background-color: $lavender;
          position: relative;
          transition: all 0.3s ease-in-out;
          @media (max-width: 768px) {
            display: none;
          }

          &-multi-button {
            z-index: 0;
            position: absolute;
            bottom: 2.25rem;
            right: 2.25rem;
            border-radius: 100%;
            width: 0rem;
            height: 0rem;
            transform: translate(50%, 50%);
            transition: 0.25s cubic-bezier(0.25, 0, 0, 1);
            & button {
              display: grid;
              place-items: center;
              position: absolute;
              width: 3rem;
              height: 3rem;
              border: none;
              border-radius: 100%;
              background: $white;
              color: $lavender;
              transform: translate(50%, 50%);
              font-size: 1.5rem;
              cursor: pointer;
              transition: 0.25s cubic-bezier(0.25, 0, 0, 1);
              box-shadow: 0 0 0rem -0.25rem black;
              &:nth-child(2) {
                background: $farTextBlack;
                color: $white;
                border: 2px solid $white;
              }
              &:hover {
                background: $farTextBlack;
                color: $white;
                box-shadow: 0 0 1rem -0.25rem black;
                border: 2px solid $farTextBlack;
              }
              &:hover:nth-child(2) {
                background: $white;
                color: $lavender;
                border: 2px solid $white;
              }
              &:first-child:nth-last-child(2),
              &:first-child:nth-last-child(2) ~ * {
                //If there are 2 children
                &:nth-child(1) {
                  right: 37.5%;
                  bottom: 18.75%;
                }
                &:nth-child(2) {
                  right: 18.75%;
                  bottom: 37.5%;
                }
              }
            }
          }
        }
        &--five {
          grid-column: 2 / 3;
          grid-row: 2 / 3;
          background-color: transparent;
          position: relative;
          transition: all 0.3s ease-in-out;
          opacity: 0;
          @media (max-width: 768px) {
            display: none;
          }
        }
      }
    }
  }
}

// Skills
.cc {
  &-skills {
    &__section {
      padding: 5rem 0;
      @media (max-width: 480px) {
        padding: 0;
      }
    }

    &__carousel {
      position: relative;
      width: 200px;
      perspective: 800px;
      z-index: 2;
      margin: 0 auto;
      margin-top: 5rem;
      -webkit-user-select: none; /* Safari */
      -moz-user-select: none; /* Firefox */
      -ms-user-select: none; /* IE10+/Edge */
      user-select: none; /* Standard */
      @media (max-width: 768px) {
        width: 150px;
        perspective: 1200;
      }

      &-wrapper {
        position: absolute;
        width: 100%;
        height: 100%;
        transform-style: preserve-3d;
        transform: rotateY(-360deg) translateZ(-300px);
        animation: rotate 30s steps(10000, end) infinite;
      }
      &-item {
        position: absolute;
        width: 200px;
        height: 100px;
        @media (max-width: 768px) {
          width: 150px;
          height: 100px;
        }

        & img {
          width: 200px;
          height: 100px;
          @media (max-width: 768px) {
            width: 150px;
            height: 100px;
          }
        }
        &--one {
          transform: rotateY(0deg) translateZ(300px);
        }
        &--two {
          transform: rotateY(30deg) translateZ(300px);
        }
        &--three {
          transform: rotateY(60deg) translateZ(300px);
        }
        &--four {
          transform: rotateY(90deg) translateZ(300px);
        }
        &--five {
          transform: rotateY(120deg) translateZ(300px);
        }
        &--six {
          transform: rotateY(150deg) translateZ(300px);
        }
        &--seven {
          transform: rotateY(180deg) translateZ(300px);
        }
        &--eight {
          transform: rotateY(210deg) translateZ(300px);
        }
        &--nine {
          transform: rotateY(240deg) translateZ(300px);
        }
        &--ten {
          transform: rotateY(270deg) translateZ(300px);
        }
        &--eleven {
          transform: rotateY(300deg) translateZ(300px);
        }
        &--twelve {
          transform: rotateY(330deg) translateZ(300px);
        }
      }
    }
  }
  &-canvas {
    &-container {
      position: relative;
      display: flex;
      height: 80%;
      width: 100%;
      justify-content: center;
      align-items: center;
    }
    width: 100%;
    height: 100%;
  }
}

.cc {
  &-contact {
    &__section {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      flex-wrap: nowrap;
      height: 100%;
      z-index: 2;
      @media (max-width: 480px) {
        justify-content: flex-start;
        width: 100%;
        height: 90%;
      }

      &--small {
        padding: 0;
        @media (max-width: 480px) {
          padding: 0 2rem;
        }

        & .cc-contact-content {
          display: flex;
          flex-direction: row;
          width: 100%;
          height: 100%;
          position: relative;
          @media (max-width: 768px) {
            flex-direction: column;
          }
        }

        & .cc-contact-map {
          width: 100%;
          height: 100%;
          z-index: 2;
          pointer-events: none;
          // Make text unselectable
          -webkit-user-select: none; /* Safari */
          -moz-user-select: none; /* Firefox */
          -ms-user-select: none; /* IE10+/Edge */
          user-select: none; /* Standard */

          &__wrapper {
            width: 100%;
            height: 100%;

            &-content {
              width: 100%;
              height: 100%;
              border-top-right-radius: 15px;
              border-bottom-right-radius: 15px;
              @media (max-width: 768px) {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
                border-bottom-right-radius: 15px;
                border-bottom-left-radius: 15px;
              }
            }
          }
        }
      }
    }

    &-form {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      background-color: $farTextBlack;
      border-top-left-radius: 15px;
      border-bottom-left-radius: 15px;
      justify-content: space-evenly;
      align-items: flex-end;
      padding: 0 3rem;
      position: relative;
      @media (max-width: 1440px) {
        padding: 5rem 3rem;
      }
      @media (max-width: 768px) {
        padding: 1rem 1.5rem 0 1.5rem;
        border-bottom-left-radius: 0;
        border-top-right-radius: 15px;
      }

      &-overlay {
        background-color: $farTextBlack;
        width: 55%;
        height: 100%;
        position: absolute;
        border-radius: 15px;
        flex-direction: column;
        justify-content: center;
        padding: 5rem 0;
        align-items: center;
        z-index: -1;
        // Make text unselectable
        -webkit-user-select: none; /* Safari */
        -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* IE10+/Edge */
        user-select: none; /* Standard */
        display: flex;
        @media (max-width: 768px) {
          width: 100%;
        }
        @media (max-width: 480px) {
          justify-content: flex-start;
        }

        &__title {
          font-size: 2.5rem;
          text-align: center;
          font-family: $libre;
          color: $white;
          margin-bottom: 2rem;
          animation: fadeIn 0.5s ease-in-out;
          @media (max-width: 1920px) {
            font-size: 2rem;
          }
          @media (max-width: 1440px) {
            font-size: 1.5rem;
          }
        }

        &__accents {
          color: $lavender;
        }

        &__paragraph {
          font-size: 1.5rem;
          text-align: center;
          font-family: $poppins;
          color: $white;
          animation: fadeIn 0.5s ease-in-out;
          @media (max-width: 1920px) {
            font-size: 1.25rem;
          }
          @media (max-width: 1440px) {
            font-size: 1.125rem;
          }
        }

        &__image {
          width: 20rem;
          height: 20rem;
          color: $lavender;
          animation: fadeIn 0.5s ease-in-out;
          @media (max-width: 768px) {
            width: 10rem;
            height: 10rem;
          }
        }

        &--open {
          z-index: 1;
        }
      }

      &-title {
        font-family: $libre;
        font-weight: 100;
        color: $white;
        letter-spacing: 0.3rem;
        animation: glitch 1s linear infinite;
        // Make text unselectable
        -webkit-user-select: none; /* Safari */
        -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* IE10+/Edge */
        user-select: none; /* Standard */

        &::before,
        &::after {
          content: attr(txt);
          position: absolute;
          left: 0;
        }

        &::before {
          animation: glitchTop 1s linear infinite;
          clip-path: polygon(0 0, 100% 0, 100% 33%, 0 33%);
          -webkit-clip-path: polygon(0 0, 100% 0, 100% 33%, 0 33%);
        }

        &::after {
          animation: glitchBotom 1.5s linear infinite;
          clip-path: polygon(0 67%, 100% 67%, 100% 100%, 0 100%);
          -webkit-clip-path: polygon(0 67%, 100% 67%, 100% 100%, 0 100%);
        }
      }

      &__field {
        width: 100%;
        padding: 1rem 0;
        display: flex;
        flex-direction: column;
        @media (max-width: 768px) {
          padding: 0.25rem 0;
        }

        & label {
          font-family: $poppins;
          color: $white;
          font-size: 1.125rem;
          padding: 0.5rem 0;
          @media (max-width: 768px) {
            font-size: 0.75rem;
          }
        }

        & input {
          padding: 1rem;
          font-family: $poppins;
          color: $white;
          background-color: $black;
          border: none;
          @media (max-width: 768px) {
            padding: 0.5rem;
            font-size: 0.75rem;
          }

          &::placeholder {
            color: $white;
            opacity: 0.375;
          }
        }
      }
      &__textarea {
        width: 100%;
        padding: 1rem 0;
        display: flex;
        height: 50%;
        @media (max-width: 768px) {
          padding: 0.25rem;
        }

        flex-direction: column;

        & label {
          font-family: $poppins;
          color: $white;
          font-size: 1.125rem;
          padding: 0.5rem 0;
          @media (max-width: 768px) {
            font-size: 0.75rem;
          }
        }

        & textarea {
          padding: 1rem;
          font-family: $poppins;
          color: $white;
          background-color: $black;
          border: none;
          resize: none;
          @media (max-width: 768px) {
            padding: 0.5rem;
            font-size: 0.75rem;
          }

          &::placeholder {
            color: $white;
            opacity: 0.375;
          }
        }

        &-field {
          height: 100%;
        }
      }

      &__button {
        padding: 0.5rem 1rem;
        margin-bottom: 0.75rem;
        position: relative;
        font-size: 1rem;
        font-family: $poppins;
        font-weight: 100;
        color: $white;
        transition: all 0.3s ease-in-out;
        background: transparent;
        border: none;
        cursor: pointer;
        // Make text unselectable
        -webkit-user-select: none; /* Safari */
        -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* IE10+/Edge */
        user-select: none; /* Standard */
        border: 2px solid $black;
        border-radius: 5px;
        @media (max-width: 768px) {
          padding: 0.375rem 0.675rem;
          font-size: 0.75rem;
        }

        &:hover {
          border: 2px solid $white;
          background-color: $white;
          color: $black;
        }
      }
    }
  }
}
